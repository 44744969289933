import Image from 'next/image'
// Styles
import styles from './IconWithUnderline.module.scss'

const IconWithUnderline = ({ src, width, height, alt, onClick }) => {
  return (
    <div className={styles.iconContainer}>
      <Image
        src={src} width={width} height={height} alt='icon' className={styles.icon}
        onClick={onClick}
      />
      <div className={styles.underline} />
    </div>
  )
}

export default IconWithUnderline
