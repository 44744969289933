import styles from './Main.module.scss'
import Head from 'next/head'

export const Main = ({ title, description, children }) => {
  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name='description' content={description} />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <link rel='icon' href='/favicon.ico' />
      </Head>
      <section className={styles.template}>
        <div className={styles.template_section}>{children}</div>
        <div className={styles.template_background} />
      </section>
    </>
  )
}
Main.defaultProps = {
  title: 'Basys',
  description: ''
}
